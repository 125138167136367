/* #chatCon {
    width: 300px;
    position: fixed;
  bottom: 20px;
  right: 20px;
} */

.overlay{
    width: 100vw;
    position: absolute;
    height: 100vh;
    left: 0px;
    top: 0px;
    z-index: 0;
}

#chatCon .chat-box {
  /* display: none; */
  position: fixed;
  top: 65px;
  right: 150px;
  width: 60vw;
  height: 100%;
  border-radius: 25px;
  background-color: #eee;
}

#chatCon .pop {
  width: 100%;
  height: 25%;
  cursor: pointer;
}

#chatCon .pop p {
  text-align: right;
}

#chatCon .pop img {
  border-radius: 50%;
  width: 15%;
}
/*# sourceMappingURL=auto.css.map */
.json-to-table tr:nth-child(even){
    background-color: transparent !important;
}

@media only screen and (max-width: 600px) {
   

#chatCon .chat-box {
    /* display: none; */
    right: 0px;
    width: 100dvw;
    height: 100dvh;
    border-radius: 25px;
    background-color: #eee;
  }
    

  }

  @media screen and (-webkit-min-device-pixel-ratio:0) and (max-device-width:1024px)
  { 
    select,
    textarea,
    input {
      font-size: 16px !important;
    }
  }